<template>
    <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
          <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/robot' }">机器人管理</el-breadcrumb-item>
          <el-breadcrumb-item>机器人详情</el-breadcrumb-item>
        </el-breadcrumb>
          </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="机器人名称:" prop="">{{ form.name }}</el-form-item>
          <el-form-item label="机器人地址:" prop="">{{ form.webHook }}</el-form-item>
          <el-form-item label="备注:" prop="">
            {{ form.remarks }}
          </el-form-item>
          <el-form-item label="">
            <el-button size="small" @click="$router.push('/robot')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {},
        rules: {},
        loading: false,
      }
    },
    methods: {
      loadDetail() {
        this.loading = true
        this.$ajax.post('staffDetail', {
          id: this.row.id
        }).then(res => {
          res.data.nameC = res.data.name
          res.data.webHookC = res.data.webHook
          res.data.companyIdC = res.data.companyId
          res.data.remarksC = res.data.remarks
          this.form = res.data
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      },
    },
    mounted() {
      if (this.$route.params == null) {
        this.$router.push('/robot')
      } else {
        this.row = this.$route.params
        this.form = this.row
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .mains {
    height: 80%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  </style>